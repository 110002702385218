<template>
    <b-row>
        <b-col cols="12">
            <!-- 1 -->
            <b-card no-body class="mb-4">
                <!-- Search Control -->
                <b-card-body class="p-3 d-flex justify-content-between">
                    <b-form-group class="mb-2 w-25">
                        <b-input-group>
                            <b-form-input
                                id="filter-input"
                                v-model="filter"
                                type="search"
                                placeholder="Type to Search"
                            ></b-form-input>

                            <b-input-group-append>
                                <b-button variant="danger" :disabled="!filter" @click="filter = ''">Clear</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                    <div class="d-flex align-items-center">
                        <b-button variant="primary" class="mr-2" v-b-modal.modal-state @click="titleModal = 'CREATE'"
                            >Add</b-button
                        >
                    </div>
                </b-card-body>
                <!-- End Section -->

                <!-- Data Table -->
                <b-table
                    style="min-height: 250px"
                    show-empty
                    responsive
                    small
                    class="mb-0"
                    head-variant="light"
                    :items="states"
                    :filter="filter"
                    :fields="fields"
                    hover
                >
                    <template #cell(id)="data"> #{{ data.item.id }} </template>
                    <template #cell(isActive)="data">
                        <b-badge variant="success" v-if="data.item.isActive">Active</b-badge>
                        <b-badge variant="danger" v-else>Inactive</b-badge>
                    </template>
                    <template #cell(action)="data">
                        <b-dropdown variant="primary" id="dropdown-1" text="Actions" class="">
                            <b-dropdown-item v-b-modal.modal-state @click="editState(data.item)">Edit</b-dropdown-item>
                            <!--<b-dropdown-item v-b-modal.modal-remove>Delete</b-dropdown-item>-->
                        </b-dropdown>
                    </template>
                </b-table>
                <!-- End Section -->

                <!-- Pagination -->
                <div class="p-3 d-flex align-items-center">
                    <b-form-group
                        label="Per page"
                        label-for="per-page-select"
                        label-cols-sm="6"
                        label-cols-md="4"
                        label-cols-lg="9"
                        label-size="sm"
                        label-class="fw-medium"
                        class="mb-0"
                    >
                        <b-form-select
                            @change="getStates"
                            id="per-page-select"
                            v-model="perPage"
                            :options="pageOptions"
                            size="sm"
                        ></b-form-select>
                    </b-form-group>
                    <div class="ml-auto">
                        <b-pagination
                            @input="getStates"
                            v-model="currentPage"
                            :total-rows="totalStates"
                            :per-page="perPage"
                            align="fill"
                            class="my-0"
                        ></b-pagination>
                    </div>
                </div>
                <!-- End Section -->
            </b-card>
        </b-col>

        <!-- Modal -->
        <b-modal id="modal-state" hide-footer centered :title="titleModal" @close="closeModal">
            <b-form @submit.prevent="createState">
                <b-form-group label="Description" label-class="fw-medium mb-1" label-for="name">
                    <b-form-input
                        id="name"
                        type="text"
                        placeholder="state name here"
                        required
                        v-model="description"
                        autocomplete="off"
                    ></b-form-input>
                </b-form-group>

                <b-form-group label="Country" label-class="fw-medium mb-1" label-for="countryName">
                    <b-form-select v-model="country" class="mb-3" required>
                        <b-form-select-option :value="null">Please select an option</b-form-select-option>
                        <b-form-select-option :value="country.id" v-for="country in countries" :key="country.id">{{
                            country.name
                        }}</b-form-select-option>
                    </b-form-select>
                </b-form-group>

                <b-button block :disabled="loadingState" variant="primary" type="submit">
                    <b-spinner small v-if="loadingState"></b-spinner>
                    <span v-else>Save</span>
                </b-button>
            </b-form>
        </b-modal>

        <b-modal id="modal-remove" size="sm" hide-footer centered title="Delete State">
            <b-card-text> Are you sure to want to remove this state? </b-card-text>
            <div class="d-flex justify-content-end">
                <!-- <b-button variant="danger" class="mr-2" @click="removeState">Yes</b-button> -->
                <b-button variant="danger" class="mr-2">Yes</b-button>
                <!-- <b-button variant="secondary" @click="$bvModal.hide('modal-remove')">No</b-button> -->
                <b-button variant="secondary">No</b-button>
            </div>
        </b-modal>
        <!-- End Section -->
    </b-row>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    name: 'states',

    data: () => ({
        page: {
            title: 'states',
        },
        filter: null,
        fields: [
            {
                key: 'id',
                label: 'No',
                sortable: true,
            },
            {
                key: 'name',
                label: 'State',
                sortable: true,
            },
            {
                key: 'countryName',
                label: 'Country',
                sortable: true,
            },
            {
                key: 'action',
                label: 'Actions',
                sortable: false,
            },
        ],

        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [5, 10, 20, 40, { value: 100, text: 'Show a lot' }],
        titleModal: 'Create State',
        loadingState: false,

        description: '',
        country: null,
        stateSelected: {},
    }),
    components: {},
    methods: {
        clearValues() {
            this.description = '';
            this.country = null;
            this.stateSelected = {};
        },
        closeModal() {
            this.isEditing = false;
            this.clearValues();
            this.titleModal = 'Create Bank';
        },
        async getData() {
            await this.getStates();
            await this.getCountries();
            await this.$store.dispatch('authorizations/get_allAuthorizations');
        },
        async getStates() {
            let data = {
                skip: this.currentPage,
                take: this.perPage,
            };
            await this.$store.dispatch('general/states/get_allStates', data);
        },
        async getCountries() {
            let data = {
                skip: 1,
                take: 999,
            };
            await this.$store.dispatch('general/countries/get_allCountries', data);
        },
        async editState(state) {
            this.clearValues();
            const stateData = await this.$store.dispatch('general/states/get_states', state.id);
            this.stateSelected = stateData;
            this.isEditing = true;
            this.description = stateData.name;
            this.country = stateData.countryId; // brings the previous country value
            this.country = stateData.countryId; // copy the new country value
            this.titleModal = 'EDIT';
        },
        async createState() {
            this.loadingState = true;
            let data = {
                description: this.description,
                countryId: this.country,
            };

            if (this.isEditing) {
                (data.id = this.stateSelected.id), await this.$store.dispatch('general/states/update_state', data);
            } else {
                await this.$store.dispatch('general/states/create_state', data);
            }

            this.clearValues();
            await this.getStates();
            this.$bvModal.hide('modal-state');
            this.loadingState = false;
        },
    },
    computed: {
        ...mapGetters({
            states: 'general/states/getStates',
            totalStates: 'general/states/getTotalStates',
            authorizations: 'authorizations/getAuthorizations',
            countries: 'general/countries/getCountries',
        }),
    },
    mounted() {},
    created() {
        this.getData();
    },
};
</script>
